import React, { PropsWithChildren, ReactNode } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex
} from '@chakra-ui/react';

export type SidePanelSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

interface ISidePanelProps {
    bodyStyles?: React.CSSProperties;
    closeButtonLabel?: string;
    footerContent?: ReactNode;
    headingContent?: ReactNode;
    isDarkTheme?: boolean;
    isOpen: boolean;
    hideCloseButton?: boolean;
    size?: SidePanelSize;
    onClose: () => void;
}

const SidePanel: React.FC<PropsWithChildren<ISidePanelProps>> = ({
    bodyStyles,
    children,
    closeButtonLabel,
    footerContent,
    headingContent,
    isDarkTheme,
    isOpen,
    hideCloseButton = false,
    size = 'lg',
    onClose
}) => {
    return (
        <Drawer isOpen={isOpen} onClose={onClose} size={size}>
            <DrawerOverlay data-testid="side-panel-overlay" />
            <DrawerContent borderRadius="0" data-testid="side-panel">
                <DrawerHeader
                    borderBottomColor={isDarkTheme ? 'black' : 'gray.300'}
                    borderBottomStyle="solid"
                    borderBottomWidth="1px"
                    fontSize="24px"
                    fontWeight="normal"
                    height="60px"
                    paddingX="20px"
                    paddingY="12px"
                    zIndex="2"
                >
                    <Flex
                        data-testid="side-panel-header"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {headingContent}
                        {!hideCloseButton && (
                            <DrawerCloseButton
                                aria-label={closeButtonLabel || 'Close'}
                                color="inherit"
                                data-testid="side-panel-close-button"
                                background="transparent"
                                marginLeft="auto"
                                marginTop="0"
                                padding="8px"
                                position="inherit"
                                zIndex="1"
                                _hover={{
                                    color: 'brand.primary'
                                }}
                            />
                        )}
                    </Flex>
                </DrawerHeader>
                <DrawerBody
                    data-testid="side-panel-body"
                    id="side-panel-body"
                    padding="0px"
                    zIndex="3"
                    style={bodyStyles}
                >
                    {children}
                </DrawerBody>
                {footerContent && (
                    <DrawerFooter
                        data-testid="side-panel-footer"
                        borderTopColor={isDarkTheme ? 'black' : 'gray.200'}
                        borderTopStyle="solid"
                        borderTopWidth="1px"
                        paddingX="20px"
                        paddingY="12px"
                        zIndex="1"
                    >
                        {footerContent}
                    </DrawerFooter>
                )}
            </DrawerContent>
        </Drawer>
    );
};

export default SidePanel;
